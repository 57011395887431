import React from "react";

function Banner() {
    return (
        <>
            <div className="banner">
                <img src="https://tqrg.github.io/icse2024/images/icse-banner-simple.png" alt="banner" width='100%' />
            </div>
        </>
    );
}

export default Banner;